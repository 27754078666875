import React, { useState } from "react";
import axios from "axios";
import "../styles/uploadRates.css"; // Make sure to create this CSS file

function FileUploadPage() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [updatedRates, setUpdatedRates] = useState([]);
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedFiles.length > 0) {
      console.log("Submitting file:", selectedFiles);
      setUpdatedRates([]);
      const formData = new FormData();
      Array.from(selectedFiles).forEach((file) => {
        formData.append("files", file);
      });
      try {
        const response = await axios.post(
          "https://api.icarslogistics.com/api/rates/uploadcsv",
          formData,
          {
            headers: {
              "Content-Type": "multiple/form-data",
            },
          }
        );
        console.log(response.data);
        if (response.data.length > 0) setUpdatedRates(response.data);
      } catch (error) {
        console.log("Error uploading files: " + error);
        if (error.response) console.log(error.response.data);
      }
      // Implement the file submit logic here
    }
  };

  return (
    <div className="file-upload-container">
      <div className="file-upload-form-container">
        <h2>Upload FCL Rates</h2>
        <form onSubmit={handleSubmit} className="file-upload-form">
          <input
            type="file"
            onChange={handleFileChange}
            className="file-input"
            multiple
          />
          <button type="submit" className="upload-button">
            Submit
          </button>
        </form>
        <a
          href="https://docs.google.com/document/d/1hNSOmaqzPxB2FDZdWojoMcPGKNjDi6JLc-IERirDKAg/edit?usp=sharing"
          target="_blank"
          class="button-style"
        >
          Tutorial
        </a>
      </div>
      {updatedRates.length > 0 && (
        <div className="rates-table-container">
          <table className="rates-table">
            <thead>
              <tr>
                <th>Ports</th>
                <th>Carrier</th>
                <th>Commodity</th>
                <th>Size</th>
                <th>Price</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {updatedRates.map((rate, index) => (
                <tr key={index}>
                  <td>{rate.ports}</td>
                  <td>{rate.carrier}</td>
                  <td>{rate.commodity}</td>
                  <td>{rate.size}</td>
                  <td>{rate.price}</td>
                  <td>{rate.update}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default FileUploadPage;
