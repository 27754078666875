import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import "../styles/navigation.css";
import { AuthenticationContext } from "./authenticationContext.js";
import LogoutButton from "./logoutButton.js";

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const user = useContext(AuthenticationContext);

  // Toggles the menu open/closed
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navBar">
      {/* Hamburger Icon */}
      <div className="hamburger-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Mobile Navigation Links */}
      <ul className={`mobile-navList ${isOpen ? "open" : ""}`}>
        <div className="close-icon" onClick={toggleMenu}>
          <div className="bar diagonal part1"></div>
          <div className="bar diagonal part2"></div>
        </div>

        <li className="navItems">
          <NavLink to="/" onClick={() => setIsOpen(false)}>
            Home
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/airfreightcontacts" onClick={() => setIsOpen(false)}>
            Air Freight Contacts
          </NavLink>
        </li>

        <li className="navItems">
          <NavLink
            to="/belgiumcarriercommodity"
            onClick={() => setIsOpen(false)}
          >
            Belgium Carrier Commodity Details
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/carrates" onClick={() => setIsOpen(false)}>
            Car Rates
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/carriercontacts" onClick={() => setIsOpen(false)}>
            Carrier Contacts
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink
            to="carrieruserandpasswords"
            onClick={() => setIsOpen(false)}
          >
            Carrier User And Passwords
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/carrierdemurrage" onClick={() => setIsOpen(false)}>
            CBP Holds Carrier Demurrage Costs
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/uscustoms" onClick={() => setIsOpen(false)}>
            CBP Holds Exams
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink
            to="/centraldispatchinlands"
            onClick={() => setIsOpen(false)}
          >
            Central Dispatch Inlands
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/comparerates" onClick={() => setIsOpen(false)}>
            Compare Rates
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink
            to="/documentsrequiredexport"
            onClick={() => setIsOpen(false)}
          >
            Documents Required For Export
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/exportlicenses" onClick={() => setIsOpen(false)}>
            Export Licenses
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/fclcoloaders" onClick={() => setIsOpen(false)}>
            FCL Co Loaders
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/rates" onClick={() => setIsOpen(false)}>
            FCL Rates
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/fumigationrequest" onClick={() => setIsOpen(false)}>
            Fumigation Request
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/hazprocedures" onClick={() => setIsOpen(false)}>
            Haz Procedures
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/insurancehhgunirisc" onClick={() => setIsOpen(false)}>
            Insurance HHG Unirisc
          </NavLink>
        </li>

        {user && user.email !== "moving@shipdei.com" && (
          <li className="navItems">
            <NavLink to="/loadingrates" onClick={() => setIsOpen(false)}>
              Loading Rates
            </NavLink>
          </li>
        )}

        <li className="navItems">
          <NavLink to="/receivingwarehouses" onClick={() => setIsOpen(false)}>
            Receiving Warehouses
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/rorocarriers" onClick={() => setIsOpen(false)}>
            Roro Carriers
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/shiphawaiiandmore" onClick={() => setIsOpen(false)}>
            Shipping to HI, AK, PR
          </NavLink>
        </li>
        <li className="navItems">
          <NavLink to="/terminalspierpass" onClick={() => setIsOpen(false)}>
            Terminals & Pier Pass
          </NavLink>
        </li>
        {user &&
          (user.email === "dispatch@shipdei.com" ||
            user.email === "chris@shipdei.com" ||
            user.email === "chriswin12@gmail.com") && (
            <li className="navItems">
              <NavLink to="/uploadrates" onClick={() => setIsOpen(false)}>
                Upload FCL Rates
              </NavLink>
            </li>
          )}
        {user &&
          (user.email === "dispatch@shipdei.com" ||
            user.email === "chris@shipdei.com" ||
            user.email === "chriswin12@gmail.com") && (
            <li className="navItems">
              <NavLink to="/uploadcarrates" onClick={() => setIsOpen(false)}>
                Upload Car Rates
              </NavLink>
            </li>
          )}
        {user &&
          (user.email === "dispatch@shipdei.com" ||
            user.email === "chris@shipdei.com" ||
            user.email === "chriswin12@gmail.com") && (
            <li className="navItems">
              <NavLink
                to="/uploadloadingrates"
                onClick={() => setIsOpen(false)}
              >
                Upload Loading Rates
              </NavLink>
            </li>
          )}
        <li className="navItems">
          <NavLink to="/uscontainerdray" onClick={() => setIsOpen(false)}>
            US Container Dray
          </NavLink>
        </li>
        <a
          href="https://drive.google.com/file/d/15LehwtMOezryLCniYhVFwuiT8Z80didV/view?usp=sharing"
          target="_blank"
          className="rateButton"
          onClick={() => setIsOpen(false)}
        >
          US Customs Import Clark POA
        </a>
        <li className="navItems">
          <NavLink to="/vgminstructions" onClick={() => setIsOpen(false)}>
            VGM Instructions
          </NavLink>
        </li>
        {user ? (
          <li className="navItems">
            <LogoutButton onClick={() => setIsOpen(false)} />
          </li>
        ) : (
          <li className="navItems">
            <NavLink to="/login" onClick={() => setIsOpen(false)}>
              Login
            </NavLink>
          </li>
        )}
      </ul>

      {/* Desktop Navigation */}

      <ul className="navList navScroll">
        <li className="navItems long-text">
          <NavLink to="/">Home</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/airfreightcontacts">Air Freight Contacts</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/belgiumcarriercommodity">
            Belgium Carrier Commodity Details
          </NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/carrates">Car Rates</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/carriercontacts">Carrier Contacts</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink
            to="carrieruserandpasswords"
            onClick={() => setIsOpen(false)}
          >
            Carrier User And Passwords
          </NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/carrierdemurrage">
            CBP Holds Carrier Demurrage Costs
          </NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/uscustoms">CBP Holds Exams</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/centraldispatchinlands">
            Central Dispatch Inlands
          </NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/comparerates">Compare Rates</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/documentsrequiredexport">
            Documents Required For Export
          </NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/exportlicenses">Export Licenses</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/fclcoloaders">FCL Co Loaders</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/rates">FCL Rates</NavLink>
        </li>

        <li className="navItems long-text">
          <NavLink to="/fumigationrequest">Fumigation Request</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/hazprocedures">Haz Procedures</NavLink>
        </li>

        <li className="navItems long-text">
          <NavLink to="/insurancehhgunirisc">Insurance HHG Unirisc</NavLink>
        </li>

        {user && user.email !== "moving@shipdei.com" && (
          <li className="navItems long-text">
            <NavLink to="/loadingrates">Loading Rates</NavLink>
          </li>
        )}
        <li className="navItems long-text">
          <NavLink to="/receivingwarehouses">Receiving Warehouses</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/rorocarriers">Roro Carriers</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/shiphawaiiandmore">Shipping to HI, AK, PR</NavLink>
        </li>
        <li className="navItems long-text">
          <NavLink to="/terminalspierpass">Terminals & Pier Pass</NavLink>
        </li>
        {user &&
          (user.email === "dispatch@shipdei.com" ||
            user.email === "chris@shipdei.com" ||
            user.email === "chriswin12@gmail.com") && (
            <li className="navItems long-text">
              <NavLink to="/uploadrates">Upload FCL Rates</NavLink>
            </li>
          )}
        {user &&
          (user.email === "dispatch@shipdei.com" ||
            user.email === "chris@shipdei.com" ||
            user.email === "chriswin12@gmail.com") && (
            <li className="navItems long-text">
              <NavLink to="/uploadcarrates">Upload Car Rates</NavLink>
            </li>
          )}
        {user &&
          (user.email === "dispatch@shipdei.com" ||
            user.email === "chris@shipdei.com" ||
            user.email === "chriswin12@gmail.com") && (
            <li className="navItems long-text">
              <NavLink to="/uploadloadingrates">Upload Loading Rates</NavLink>
            </li>
          )}
        <li className="navItems long-text">
          <NavLink to="/uscontainerdray">US Container Dray</NavLink>
        </li>
        <a
          href="https://drive.google.com/file/d/15LehwtMOezryLCniYhVFwuiT8Z80didV/view?usp=sharing"
          target="_blank"
          className="navItems long-text"
        >
          US Customs Import Clark POA
        </a>
        <li className="navItems long-text">
          <NavLink to="/vgminstructions">VGM Instructions</NavLink>
        </li>
        {user ? (
          <li className="navItems long-text">
            <LogoutButton />
          </li>
        ) : (
          <li className="navItems long-text">
            <NavLink to="/login">Login</NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Navigation;
